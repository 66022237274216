import img020 from "../main/0-20.png"
import img520 from "../main/5-20.png"
import img530 from "../main/5-30.png"
import img1030 from "../main/10-30.png"



 let data = [
    {
        img: img020,
        size: "0-20W",
        type: "FULLY SYNTHETIC",
        api: "API SN"
    },
    {
        img: img520,
        size: "5-20W",
        type: "FULLY SYNTHETIC",
        api: "API SN"
    },
    {
        img: img530,
        size: "5-30W",
        type: "FULLY SYNTHETIC",
        api: "API SN"
    },
    {
        img: img1030,
        size: "10-30W",
        type: "FULLY SYNTHETIC",
        api: "API SN"
    },
];
export default data;