import React, { useEffect, useState } from 'react'
import "./main.css"
import img020 from "./0-20.png"
import img520 from "./5-20.png"
import img530 from "./5-30.png"
import image2 from "./lamb.jpg"
import image4 from "./porsche.jpg"
import image5 from "./ferrari.jpg"


export default function Main() {

  const [count, setCount] = useState(1);

  const myStyle = {
    backgroundImage: `url(${image2})`,
    height: "100vh",
    marginTop: "-70px",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",


  };
  const myStyle2 = {
    backgroundImage: `url(${image4})`,
    height: "100vh",
    marginTop: "-70px",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
  };
  const myStyle3 = {
    backgroundImage: `url(${image5})`,
    height: "100vh",
    marginTop: "-70px",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
  };

  useEffect(() => {
    setInterval(() => {
      setCount((count) => { return count === 3 ? 1 : count + 1 })
    }, 4000);
  }, []);

  const text = {
    text1: `Peace of Mind `,
    text11: `after Mile`,
    span1: "Mile",
    text2: "Join the",
    span2: "sega 1",
    text22: "Revolution"
  }
  return (
    <div>
      <div className='main-main'>

        <div style={count === 1 ? myStyle : count === 2 ? myStyle2 : myStyle3} className="image-slider"></div>

        <div className="content-main">
          <h1 className='hh1'>{count === 1 ? text.text1 : text.text2} <br /><span>{count === 1 ? text.span1 : text.span2}</span><br /><h1 className='hh1'>{count === 1 ? text.text11 : text.text22}</h1></h1>
          <img className='oil-img' src={count===1?img020:count===2?img520:img530} />
        </div>


      </div>
    </div>
  )
}
