import React from 'react'
import "./item.css"

export default function Item(props) {
  return (
    <div>
      <div className="main-items">
        <img src={props.image} alt='photo' />
       
        <h4 className='title1'>{props.type}</h4>
        <div style={{display:"flex"}}>
        <h5 className='title2'>{props.size}</h5>
        <h5 className='title3'>{props.api}</h5>
        
        </div>
      </div>
    </div>
  )
}
