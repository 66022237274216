import React from 'react'
import "./why.css"
import sega from "../navbar/sega1 logo-01.png"

export default function Why() {
    return (
        <div className='why-main'>
            <h1 >why Sega 1</h1>
            <hr />
            <div className="why-content">
                <img src={sega} alt="" />
                <h3>because it's more than just oil; it's an advanced formula designed to shield your engine from the harsh realities of the road. With powerful detergents and additives, our oil fights against sludge buildup, keeping vital engine components clean and free from deposits.</h3>
            </div>
        </div>
    )
}
