import React from 'react'
import "./navbar.css"
import logo from "./sega1 logo-01.png"



export default function Navbar() {
  return (

      <div className='main-nav'>
        <img className='logo' src={logo} alt="" />
        <ul className='list-nav'>
          <li>Home</li>
          <li>products</li>
          <li>contact us</li>
        </ul>
      </div>
    
  )
}
