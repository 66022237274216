
import './App.css';
import Navbar from './component/pages/navbar/Navbar';
import Main from './component/pages/main/Main';
import Why from './component/pages/Whyus/Why';
import Products from './component/pages/products/Products';
import ContactUs from './component/pages/contact/ContactUs';
import Footer from './component/pages/footer/Footer';

function App() {
  return (
    <div className="App">
     <Navbar/>
     <Main/>
     <Why/>
     <Products/>
     <ContactUs/>
     <Footer/>
    </div>
  );
}

export default App;
