import React from 'react'
import "./product.css"
import data from "./data"
import Item from './Item'

 function Products() {

    
    return(
        <div className="mainnn">
        <h1>products</h1>
        <div className='main-product'>{data.map((item,i)=>{return <Item key={i} image={item.img} type={item.type} size={item.size} api={item.api }/>})}</div>
        </div>
    )
    
};
export default Products;