import React from 'react'
import "./footer.css"
import logo from "../navbar/sega1 logo-01.png"

export default function Footer() {
    return (
        <div className='main-footer'>
            <div className="container-footer">
                <div className="left-footer">
                    <img className='logo-footer' src={logo} alt="" />
                    <p>copy right 2024 | all right received</p>
                </div>
                <div className="right-footer">
                    <ul className='list-footer'>
                        <li>Home</li>
                        <hr />
                        <li>Products</li>
                        <hr />
                        <li>Countact Us</li>
                        <hr />
                        <h4>info@sega1oil.com</h4>
                    </ul>
                </div>
            </div>
        </div>
    )
}
