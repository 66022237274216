import React, { useState } from 'react'
import "./contact.css"

export default function ContactUs() {

    const [name,setName]=useState("");
    const [email,setEmail]=useState("");
    const [phone,setPhone]=useState("");
    console.log(name)

    function submit(e){
        e.preventDefault();
        setEmail("");
        setName("");
        setPhone("");
    }
   
    return (
        <div className='contact-main'>
            <h1>keep in touch</h1>
            <div className="contact-form">
                <form className="container-inputs" onSubmit={submit}>
                    <p>please fill the form below to stay in touch</p>
                    <input type="text" placeholder='name...' value={name} onChange={(e)=> setName( e.target.value)} required />
                    <input type="text" placeholder='Email...' value={email} onChange={(e)=> setEmail( e.target.value)} required/>
                    <input type="text" placeholder='Phone Number...' value={phone} onChange={(e)=> setPhone( e.target.value)} required/>
                    <button type='submit'>send</button>
                </form>
                <div className="content-contact">
                    <h1>contact us</h1>
                </div>
            </div>
        </div>
    )
}
